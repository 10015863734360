import moment from 'moment';
import 'moment/locale/de';

export class Message {
  id: number;
  messageable_id: number;
  messageable_type: string;
  body: string;
  document_ids: Array<number>;
  creator_id: number;
  author_name: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;

  get createdAt() {
    return this.created_at ? moment(this.created_at) : null;
  }

  get bodyHtmlLess() {
    return this.body.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ');
  }

  fromJson(source: any): Message {
    Object.assign(this, source);
    return this;
  }
}

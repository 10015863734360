import moment from 'moment';
import 'moment/locale/de';

export class Note {
  id: number;
  noteable_id: number;
  noteable_type: string;
  body: string;
  mentions: Array<number>;
  state: string;
  creator_id: number;
  author_name: string;
  author_profile_picture_thumb: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  noteable_title: string;
  noteable_link: string;
  noteable_icon: string;

  get createdAt() {
    return this.created_at ? moment(this.created_at) : null;
  }

  get bodyHtmlLess() {
    return this.body.replace(/<[^>]*>?/gm, '').replace(/&nbsp;/g, ' ');
  }

  get replacedBody() {
    return this.body.replace(/<a /gm, '<a target=_blank ').replace(/color:blue;/gm, ' ').replace(/color: blue;/gm, ' ');
  }

  fromJson(source: any): Note {
    Object.assign(this, source);
    return this;
  }
}

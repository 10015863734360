export class ExportOperation {
  static companyCols: string[] = [
    'id',
    'org_title',
    'email',
    'state_i18n',
    'priority_i18n',
    'sel_represented_by_i18n',
    'represented_by_value',
    'employees_number',
    'total_salaries',
    'sector',
    'revenue',
    'tax_number',
    'vat_number',
    'foundation_date',
    'phone_work',
    'phone_mobile',
    'phone_fax',
    'street',
    'housenumber',
    'zip',
    'city',
    'country',
    'contracts_active_count',
    'contracts_incomplete_count',
    'damages_opened_count',
    'messages_count',
    'documents_count',
    'email_0',
    'email_1',
    'bank_details_0_title',
    'bank_details_0_iban',
    'bank_details_0_bic',
    'bank_details_1_title',
    'bank_details_1_iban',
    'bank_details_1_bic',
    'bank_details_2_title',
    'bank_details_2_iban',
    'bank_details_2_bic',
    'responsible_names',
    'mailing_address_enabled',
    'mailing_name',
    'mailing_co',
    'mailing_street',
    'mailing_housenumber',
    'mailing_zip',
    'mailing_city',
    'mailing_country',
    'stock_amount_total_sum'
  ];

  static individualCols: string[] = [
    'id',
    'salutation_i18n',
    'first_name',
    'last_name',
    'email',
    'priority_i18n',
    'marital_status_i18n',
    'state_i18n',
    'title',
    'dob',
    'phone',
    'phone_work',
    'phone_mobile',
    'phone_fax',
    'current_proficiency',
    'learned_proficiency',
    'position_proficiency',
    'retirement_age',
    'street',
    'housenumber',
    'zip',
    'city',
    'country',
    'tax_number',
    'vat_number',
    'revenue',
    'miscellaneous',
    'ident_art_i18n',
    'ident_nummer',
    'ident_amt',
    'ident_start',
    'ident_end',
    'contracts_active_count',
    'contracts_incomplete_count',
    'damages_opened_count',
    'messages_count',
    'documents_count',
    'email_0',
    'email_1',
    'bank_details_0_title',
    'bank_details_0_iban',
    'bank_details_0_bic',
    'bank_details_1_title',
    'bank_details_1_iban',
    'bank_details_1_bic',
    'bank_details_2_title',
    'bank_details_2_iban',
    'bank_details_2_bic',
    'responsible_names',
    'mailing_address_enabled',
    'mailing_name',
    'mailing_co',
    'mailing_street',
    'mailing_housenumber',
    'mailing_zip',
    'mailing_city',
    'mailing_country',
    'stock_amount_total_sum'
  ];

  static contractCols: string[] = [
    'id',
    'client_id',
    'client_name',
    'insurance_group_name',
    'insurance_category_name',
    'title',
    'contract_number',
    'start_date',
    'end_date',
    'insurance_company_name',
    'insurance_company_logo',
    'state_i18n',
    'stored_state_i18n',
    'sel_insured_risk_i18n',
    'insurable_entries',
    'contract_source_i18n',
    'payment_type_i18n',
    'dynamic',
    'amount',
    'netto_amount',
    'payment_amount',
    'year_amount',
    'tax',
    'visibility_i18n',
    'created_at',
    'updated_at',
    'description',
    'payment_method_i18n',
    'bank_detail_txt',
    'damages_opened_count',
    'messages_count',
    'documents_count',
    'dynamic_fields',
    'client_responsible_names',
    'product_title',
    'acquisition_amount_total',
    'stock_amount_total'
  ];
  // todo: what about dynamic_amount_total for contracts?
  static damageCols: string[] = [
    'client_entry_title',
    'contract_entry_title',
    'contract_number',
    'internal_key',
    'art',
    'loss_date',
    'report_date',
    'closed_date',
    'amount',
    'vr_number',
    'state_i18n',
    'damage_fields',
    'sel_insured_risk_i18n',
    'insurable_entries'
  ];

  static riskCols: string[] = [
    'client_entry_title',
    'id',
    'risk_formular_title',
    'title',
    'insurable_contracts',
    'stored_state_i18n',
    'risk_fields'
  ];

  static pdfDefaultcontractCols: string[] = [
    'title',
    'contract_number',
    'insurance_company_name',
    'year_amount',
    'state_i18n'
  ];
  static pdfDefaultindividualCols: string[] = [
    'salutation_i18n',
    'first_name',
    'last_name',
    'email',
    'dob'
  ];
  static pdfDefaultcompanyCols: string[] = [
    'org_title',
    'email',
    'state_i18n',
    'priority_i18n'
  ];
  static pdfDefaultdamageCols: string[] = [
    'contract_entry_title',
    'vr_number',
    'art',
    'amount',
    'loss_date',
    'state_i18n'
  ];
  static pdfDefaultriskCols: string[] = [
    'client_entry_title',
    'id',
    'risk_formular_title',
    'title',
    'insurable_contracts',
    'stored_state_i18n',
    'risk_fields'
  ];

  static pdfFonts: string[] = ['dmsans', 'helvetica', 'courier', 'timesroman', 'arial', 'trebuc', 'georgia', 'verdana', 'times', 'cour', 'comic'];
  static pdfCovers: string[] = ['no_cover', 'default_cover', 'own_cover'];
  static pdfSizes: string[] = ['size_four', 'size_three'];
  static pdfLayouts: string[] = ['landscape', 'portrait'];

  id: number;
  user_id: number;
  export_type: string;
  status: string;
  cols: {
    individual?: Array<string>,
    company?: Array<string>,
    contract?: Array<string>,
    damage?: Array<string>,
    risk?: Array<string>
  };
  items: any;
  data: string;
  data_filename: string;
  data_type: string;
  created_at: string;
  updated_at: string;
  client_id: number;
  for_client_documents: boolean;
  pdf_font: string;
  pdf_cover: string;
  pdf_size: string;
  pdf_layout: string;
  pdf_cover_data: string;
  pdf_with_remarks: boolean;
  include_risks: boolean;

  public static defaultColsFor(forCols): Array<string> {
    return this[`${forCols}Cols`].filter(e => {
      return [
        'bank_details_1_title',
        'bank_details_1_iban',
        'bank_details_1_bic',
        'bank_details_2_title',
        'bank_details_2_iban',
        'bank_details_2_bic',
        'contracts_active_count',
        'contracts_incomplete_count',
        'damages_opened_count',
        'messages_count',
        'documents_count',
        'insurance_company_logo',
        'visibility_i18n',
        'created_at',
        'updated_at',
        'product_title',
        'acquisition_amount_total',
        'stock_amount_total'
      ].indexOf(e) === -1;
    });
  }

  public static defaultPdfColsFor(forCols): Array<string> {
    return this[`pdfDefault${forCols}Cols`];
  }

  fromJson(source: any): ExportOperation {
    Object.assign(this, source);
    return this;
  }
}
